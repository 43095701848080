import { Controller } from 'stimulus'
export default class extends Controller {
  static targets = ['submit']

  connect() {
    // See https://leastbad.com/stimulus-power-move
    this.element[this.identifier] = this

    if (this.hasSubmitTarget) {
      this.submitButton = this.submitTarget
    } else {
      this.submitButton = this.element.querySelector('input[type="submit"')
    }
    this.checkFormValidity()
    // when any inputs change check to see if we should enable submit button
    this.element.querySelectorAll('input, select, textarea').forEach((elem) => {
      elem.addEventListener('input', (e) => {
        this.checkFormValidity()
      })
      elem.addEventListener('change', (e) => {
        this.checkFormValidity()
      })
    })
  }

  checkFormValidity() {
    if (this.submitButton == null) return

    if (this.element.checkValidity()) {
      this.submitButton.disabled = false
    } else {
      this.submitButton.disabled = true
    }
  }
}
