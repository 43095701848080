import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'autocompleteInput',
    'addressField',
    'cityField',
    'zipcodeField',
    'stateField',
    'formattedAddressField',
  ]

  static componentForm = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'administrativeAreaLevel1',
    country: 'long_name',
    postal_code: 'short_name',
  }

  connect() {
    if (typeof google != 'undefined') {
      this.initMap()
    }
  }

  initMap() {
    this.autocomplete = new google.maps.places.Autocomplete(
      this.autocompleteInputTarget,
      {
        types: ['address'],
        componentRestrictions: {
          country: ['us'],
        },
      }
    )
    this.autocomplete.setFields([
      'address_components',
      'name',
      'place_id',
      'formatted_address',
    ])
    this.autocomplete.addListener('place_changed', this.placeChanged.bind(this))
  }

  placeChanged() {
    let place = this.autocomplete.getPlace()
    if (!place.address_components) {
      console.error(`Can't find address ${place.name}`)
      return
    }

    this.formattedAddressFieldTarget.value = place.formatted_address

    let streetNumber = this._getAddressComponentType(
      place.address_components,
      'street_number'
    )?.short_name
    let route = this._getAddressComponentType(place.address_components, 'route')
      ?.long_name
    let locality = this._getAddressComponentType(
      place.address_components,
      'locality'
    )?.short_name
    let postalCode = this._getAddressComponentType(
      place.address_components,
      'postal_code'
    )?.short_name
    let administrativeAreaLevel1 = this._getAddressComponentType(
      place.address_components,
      'administrative_area_level_1'
    )?.short_name

    if (streetNumber && route) {
      this.addressFieldTarget.value = `${streetNumber} ${route}`
    } else {
      this.addressFieldTarget.value = ''
    }

    if (postalCode) {
      this.zipcodeFieldTarget.value = postalCode
    } else {
      this.zipcodeFieldTarget.value = ''
    }

    if (administrativeAreaLevel1) {
      this.stateFieldTarget.value = administrativeAreaLevel1
    } else {
      this.stateFieldTarget.value = ''
    }

    /*
     * Locality is not always present
     *  e.g. "5 Laboratory Drive, Research Triangle, NC, USA"
     *  has a neighborhood and an administrative_area_level_1 but no city
     */
    if (locality) {
      this.cityFieldTarget.value = locality
    } else {
      this.cityFieldTarget.value = ''
    }

    // Dispatch change events for all of the field targets since their value may have changed
    let event = new Event('change')
    this.addressFieldTarget.dispatchEvent(event)
    this.cityFieldTarget.dispatchEvent(event)
    this.zipcodeFieldTarget.dispatchEvent(event)
    this.stateFieldTarget.dispatchEvent(event)
  }

  _getAddressComponentType(addressComponents, type) {
    return addressComponents.find((c) => c.types.find((t) => t === type))
  }

  keydown(event) {
    if (event.key == 'Enter') {
      event.preventDefault()
    }
  }
}
