// this controller will take a calendly payload and submit it to a
// targetted form. for example, for affiliate pages we use this to
// link the submitted lead with a calendly appointment.
import { Controller } from 'stimulus'
import { isCalendlyEvent } from '../../utils'

export default class extends Controller {
  static targets = ['form', 'input']

  // link lead submission with the scheduled calendly appointment
  submitCalendlyForm(e) {
    // if lead submission id is not blank then link the lead submission ID and calendly appointment
    // that was just made. this work is more difficult than necessary because of calendly's poor api -
    // the work is done in the rails controller
    if (isCalendlyEvent(e) && e.data.event === 'calendly.event_scheduled') {
      // set form data
      this.inputTarget.value = JSON.stringify(e.data.payload)
      // submit the form
      this.formTarget.submit()
    }
  }
}
