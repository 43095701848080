import { Controller } from 'stimulus'
import Player from '@vimeo/player'
export default class extends Controller {
  initialize() {}

  connect() {}

  showVideo() {
    this._openModal()

    new Player(this.modalContentElement, {
      url: this.data.get('videoUrl'),
      responsive: true,
      autoplay: true
    })
  }

  disconnect() {
    this._reset()
  }

  _closeModal() {
    this._reset()
  }

  _reset() {
    if(this.modalElement) {
      this.modalElement.parentNode.removeChild(this.modalElement)
      this.modalElement = null
    }
    if(this._outsideClickListener) {
      document.removeEventListener('click', this._outsideClickListener)
    }
  }

  _openModal() {
    this.modalElement = document.createElement('div')
    this.modalElement.className = 'video-player-link-controller-modal'
    this.modalElement.style.position = "fixed"
    this.modalElement.style.top = '0'
    this.modalElement.style.left = '0'
    this.modalElement.style.width = '100%'
    this.modalElement.style.height = '100%'
    this.modalElement.style.background = 'rgba(0, 0, 0, 0.8)'
    this.modalElement.style.display = 'flex'
    this.modalElement.style.justifyContent = 'center'
    this.modalElement.style.alignItems = 'center'
    this.modalElement.style.zIndex = '9999'

    const closeButton = document.createElement('div')
    closeButton.className = 'close-button'
    closeButton.innerHTML = 'Close'
    this.modalElement.appendChild(closeButton)

    this.modalContentElement = document.createElement('div')
    this.modalContentElement.style.background = 'black'
    this.modalContentElement.style.width = 'calc(100% - 40px'
    this.modalContentElement.style.maxWidth = '1024px'
    this.modalContentElement.style.margin = '0 auto'

    this.modalElement.appendChild(this.modalContentElement)
    document.body.appendChild(this.modalElement)

    this._closeOnClickOutside()
  }

  _closeOnClickOutside() {
    this._outsideClickListener = (event) => {
      if (!this.modalContentElement.contains(event.target) && this._isVisible(this.modalContentElement)) { // or use: event.target.closest(selector) === null
        this._closeModal()
      }
    }

    setTimeout(() => {
      document.addEventListener('click', this._outsideClickListener)
    }, 0)
  }

  _isVisible(elem) {
    return !!elem && !!( elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length )
  }
}
