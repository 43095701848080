import { Controller } from 'stimulus'
export default class extends Controller {
  static values = {
    contentId: String
  }

  trackButtonClick() {
    if (typeof ttq != "undefined") {
      // track calendly link being clicked as a tiktok event
      ttq.track(
        'ClickButton',
        {
          content_type: 'product',
          content_id: this.contentIdValue
        }
      )
    }
  }
}
