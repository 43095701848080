import { Controller } from 'stimulus'
export default class extends Controller {
  static targets = ['submit']

  connect() {
    this.form = this.element.closest('form')
    // This gets a reference to the stimulus controller attached to
    // the form that this element is within.
    this.validatedFormController = this.form['validated-form']

    this.element.addEventListener('change', (e) => {
      this.checkValidity()
    })

    this.checkValidity()
  }

  checkValidity() {
    const group = [
      ...this.form.querySelectorAll(`input[name="${this.element.name}"]`),
    ]
    const isValid = group.some((checkbox) => checkbox.checked)
    group.forEach((checkbox) => {
      if (isValid) {
        checkbox.setCustomValidity('')
      } else {
        checkbox.setCustomValidity('One must be checked')
      }
    })
    this.validatedFormController.checkFormValidity()
  }
}
