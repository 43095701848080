import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["submitButton", "turnstileErrorBox"]
  // turnstileId - css id of where to put turnstile "captcha"
  static values = {
    sitekey: String,
    turnstileId: String
  }

  initTurstile() {
    self = this
    // explicitly render tunrstile and set some callbacks
    turnstile.render(self.turnstileIdValue, {
      sitekey: self.sitekeyValue,
      callback: function(token) {
        self.turnstileSuccess()
      },
      'error-callback': function() {
        self.turnstileError()
      }
    });
  }

  // "callback" - called when turnstile challenge is "successful"
  turnstileSuccess() {
    this.submitButtonTarget.disabled = false
  }

  // "error-called" - called when turnstile is having issues
  turnstileError() {
    this.turnstileErrorBoxTarget.classList.remove("hidden")
  }
}
