import { Controller } from 'stimulus'
import { useHotkeys } from 'stimulus-use'

export default class extends Controller {
  static values = {
    options: Object,
  }

  connect() {
    if (this.optionsValue.persist) {
      let persistedValue = localStorage.getItem(this.optionsValue.persist.key)
      let isExpanded = persistedValue ? JSON.parse(persistedValue) : true
      this.setExpanded(isExpanded)
    }

    if (this.optionsValue.hotkey) {
      let bindings = {}
      bindings[this.optionsValue.hotkey] = [this.toggle]
      useHotkeys(this, bindings)
    }
  }

  toggle() {
    let isExpanded = this.element.getAttribute('aria-expanded') === 'false'
    this.setExpanded(isExpanded)
    // expect a single id or space delimited list of ids
    this.controlled.forEach((target) => {
      if (target != null) {
        target.setAttribute('aria-expanded', isExpanded)
      }
    })
    // also toggle self
    this.element.setAttribute('aria-expanded', isExpanded)
    // Returning false stops the event and prevents default browser events
    return false
  }

  setExpanded(isExpanded) {
    this.controlled.forEach((target) => {
      if (target != null) {
        target.setAttribute('aria-expanded', isExpanded)
      }
    })
    // also toggle self
    this.element.setAttribute('aria-expanded', isExpanded)
    if (this.optionsValue.persist) {
      localStorage.setItem(
        this.optionsValue.persist.key,
        JSON.stringify(isExpanded)
      )
    }
  }

  get controlled() {
    return this.element
      .getAttribute('aria-controls')
      .split(' ')
      .map((id) => document.getElementById(id))
  }
}
