import { Controller } from 'stimulus'
import Player from '@vimeo/player'
export default class extends Controller {
  static targets = ['content']

  initialize() {
    this.content = this.contentTarget.innerHTML
    this.videoPlayerNode = document.createElement('div')
    this.videoPlayerNode.style.position = 'absolute'
    this.videoPlayerNode.style.top = '0'
    this.videoPlayerNode.style.left = '0'
    this.videoPlayerNode.style.width = '100%'
    this.videoPlayerNode.style.height = '100%'
    this.element.style.display = 'block'
    this.element.style.position = 'relative'

    this.element.appendChild(this.videoPlayerNode)
  }

  connect() {}

  disconnect() {
    this.videoPlayerNode.innerHTML = ''
    this.contentTarget.style.visibility = 'visible'
  }

  showVideo() {
    const player = new Player(this.videoPlayerNode, {
      url: this.data.get('videoUrl'),
      responsive: true,
      autoplay: true
    });

    this.contentTarget.style.visibility = 'hidden'
  }
}
