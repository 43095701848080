// tried to make a few composable stimulus controllers and failed - this is
// the end result :)
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["button", "unhideElement"]

  connect() {
  }

  toggle(e) {
    e.preventDefault()
    this.toggleAllOff()
    this.unhideElementTarget.classList.remove("hidden")
    e.target.classList.add(this.cssOnClass)
    e.target.classList.remove(this.cssOffClass)
  }

  toggleAllOff() {
    this.buttonTargets.forEach((e, index) => {
      e.classList.add(this.cssOffClass)
      e.classList.remove(this.cssOnClass)
    })
  }

  get cssOnClass() {
    return this.data.get("cssOnClass")
  }

  get cssOffClass() {
    return this.data.get("cssOffClass")
  }

  get unhideId() {
    return this.data.get("unhideId")
  }
}
