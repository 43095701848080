import { Controller } from '@hotwired/stimulus'
import { useResize } from 'stimulus-use'
import Swiper from 'swiper/bundle'

export default class extends Controller {
  static values = {
    options: Object
  }

  connect() {
    useResize(this)
    this.swiper = new Swiper(this.element, {
      ...this.defaultOptions,
      ...this.optionsValue
    })
  }

  disconnect() {
    this.swiper.destroy()
    this.swiper = undefined
  }

  resize() {
    console.debug('resized')
    // this.swiper.update()
    // this.swiper.updateSize()
    // this.swiper.updateAutoHeight(10)
    // this.swiper.destroy()
    // this.swiper = new Swiper(this.element, {
    //   ...this.defaultOptions,
    //   ...this.optionsValue
    // })
  }

  get defaultOptions() {
    return {
      centeredSlides: true,
      // initialSlide: 3,
      loop: true,
      breakpoints: {
        0: {
          spaceBetween: 10,
          slidesPerView: 1.3
        },
        768: {
          spaceBetween: 16,
          slidesPerView: 2
        },
        1200: {
          spaceBetween: 16,
          slidesPerView: 3.75
        }
      },
      updateOnWindowResize: true
    }
  }
}
